import { useState } from "react";
import styles from "../../Styles/Itinerary.module.scss";
import picture_2 from "../../img/Greece/Day 2 Athens.jpeg";
import picture_3 from "../../img/Greece/Day 3 Athens.jpeg";
import picture_4 from "../../img/Greece/Day 4 Mt. Olympas.jpeg";
import picture_5 from "../../img/Greece/Day 5 Thessalonika.jpeg";
import picture_6 from "../../img/Greece/Day 6 Kavala.jpeg";
import picture_7 from "../../img/Greece/Day 7 Philippi.jpeg";
import picture_8 from "../../img/Greece/Day 8 Berea.jpeg";
import picture_9 from "../../img/Greece/Day 9 Meteora.jpeg";
import picture_10 from "../../img/Greece/Day 10 Actium.jpeg";
import picture_11 from "../../img/Greece/Day 11 Corinth.jpeg";
import itinerary from "../../img/Greece/Itinerary_Greece_Spring_2025.pdf";
import { IoClose } from "react-icons/io5";

function GreeceItinerary() {
  //For day 2

  const [boxState, setBoxState] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const open = function (i) {
    const copy = [...boxState];
    copy[i] = true;
    setBoxState(copy);
  };
  const close = function (i) {
    const copy = [...boxState];
    copy[i] = false;
    setBoxState(copy);
  };

  return (
    <div className="pageContainer">
      <p className="title">Itinerary</p>
      <p className="secondaryTitle">April 1 - 12, 2025</p>
      <div className="printBox">
        <div className="print">
          <a href={itinerary} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>

      {/*Day 1 */}
      <div>
        <h2 className={styles.dayNumber}>Day 1, Tuesday - April 1</h2>
        <p className="text">Depart the USA</p>
      </div>
      {/*Day 2 */}
      <div>
        <h2 className={styles.dayNumber}>Day 2, Wednesday - April 2</h2>
        <p className="text">
          Arrive in Greece and travel the 30-minute drive into Athens. Get
          settle into our hotel and explore the surrounding area in the heart of
          Athens. (overnight in Athens)
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(2);
            }}
          >
            See the photo...
          </button>
        </p>
      </div>
      {/*Box 2*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[2] ? "flex" : "none" }}
      >
        <div className={styles.innerBoxPicture}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(2);
            }}
          />
          <img className={styles.justPicture} src={picture_2} alt="Athens" />
        </div>
      </div>
      {/*Day 3 */}
      <div>
        <h2 className={styles.dayNumber}>Day 3, Thursday - April 3</h2>
        <p className="text">
          Athens: Our day will include walking up to the Areopagus (Mar’s Hill),
          a prominent rock outcropping located halfway up the hill to the
          Parthenon from the Agora. Acts 17:16-34 prominently features the
          Areopagus as the setting for the Apostle Paul's Areopagus sermon to
          the city council during his visit to Athens, notably leading to the
          conversion of Damaris and Dionysius the Areopagite. Dan Glick will
          lead a Bible study here from Acts 17:16-34.
        </p>
        <p className="text">
          After this, we will be joined by a professional Greek guide, who will
          lead us up the Acropolis to tour the famous Parthenon, a former temple
          that was dedicated to the goddess Athena during the fifth century BC.
          Coming down from the Acropolis, we will stroll through the Ancient
          Agora (Marketplace) visiting the Stoa of Attalos, a stoa lined with
          shops built in the 2nd century B.C. which has since been reconstructed
          for use as the Museum of the Ancient Agora. Here, we will visit many
          other ancient ruins/buildings of ancient Athens. This is where
          philosophers like Socrates gave advice to “know thyself,” and Zeno the
          Stoic lectured on how to control one’s emotions. This is also
          considered to be the seat of Western democracy.
        </p>
        <p className="text">
          A stroll through the charming Plaka will follow. The Pláka is the old
          historical neighborhood of Athens, clustered around the northern and
          eastern slopes of the Acropolis, and incorporating labyrinthine
          streets and neoclassical architecture. Plaka is built on top of the
          residential areas of the ancient town of Athens. (overnight in Athens)
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(3);
            }}
          >
            See the photo...
          </button>
        </p>
        {/*Box 3*/}
        <div
          className={styles.popUpBoxLong}
          style={{ display: boxState[3] ? "flex" : "none" }}
        >
          <div className={styles.innerBoxPicture}>
            <IoClose
              className={styles.icon}
              onClick={() => {
                close(3);
              }}
            />
            <img className={styles.justPicture} src={picture_3} alt="Athens" />
          </div>
        </div>
      </div>
      {/*Day 4*/}
      <div>
        <h2 className={styles.dayNumber}>Day 4, Friday - April 4</h2>
        <p className="text">
          Friday – April 4 - On this day, we will drive to Thessaloniki, making
          stops near Marathon and Thermopylae (scene of the famous 300
          Spartans). The plain of Marathon is the site of the Battle of Marathon
          (490 BCE), in which the heavily outnumbered Athenian army defeated the
          Persians. It’s believed that Pheidippides, a Greek herald at the
          battle, was sent running from Marathon to Athens to announce the
          victory, which is how the marathon running race was conceived in
          modern times. But there is a lot more to this story, and you will
          learn it. Thermopylae was the scene of a famous battle (480 BCE) in
          central Greece at a mountain pass during the Persian Wars. The Greek
          forces, mostly Spartan, were led by King Leonidas. After three days of
          holding their own against the Persian king Xerxes I and his vast
          southward-advancing army, the Greeks were betrayed, and the Persians
          were able to outflank them. Sending the main army in retreat, Leonidas
          and a small contingent remained behind to resist the advance and were
          defeated. In the evening, we will settle into our lodging in
          Thessaloniki. (overnight in Thessaloniki)
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(4);
            }}
          >
            See the photo...
          </button>
        </p>
      </div>
      {/*Box 4*/}
      <div
        className={styles.popUpBoxLong}
        style={{ display: boxState[4] ? "flex" : "none" }}
      >
        <div className={styles.innerBoxPicture}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(4);
            }}
          />
          <img
            className={styles.justPicture}
            src={picture_4}
            alt="Mt. Olympas"
          />
        </div>
      </div>
      {/*Day 5*/}
      <div>
        <h2 className={styles.dayNumber}>Day 5, Saturday - April 5</h2>
        <p className="text">
          Thessaloniki (pop. 814,000) is called the second capital by the Greeks
          since it is not only the second largest city of Greece after Athens
          but also the capital of Northern Greece and Macedonia. It is
          beautifully situated by the sea. Being led by a local professional
          Greek guide, we will visit some ruins of the ancient city where Paul
          founded a church, visit the famous Rotunda and the Arch of Galerius,
          climb the White Tower with its great view of the city, view the
          statute of Alexander the Great, and take a boat ride in the
          harbor/gulf of Thessaloniki. Dan Glick will lead a Bible study from
          Acts and the epistles to the Thessalonians. (overnight in
          Thessalonica)
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(5);
            }}
          >
            See the photo...
          </button>
        </p>
      </div>
      {/*Box 5*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[5] ? "flex" : "none" }}
      >
        <div className={styles.innerBoxPicture}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(5);
            }}
          />
          <img
            className={styles.justPicture}
            src={picture_5}
            alt="Thessalonica"
          />
        </div>
      </div>
      {/*Day 6*/}
      <div>
        <h2 className={styles.dayNumber}>Day 6, Sunday - April 6</h2>
        <p className="text">
          Leaving Thessaloniki, we will travel east towards Kavala (biblical
          Neapolis). On the way, we will make stops at Apollonia and Amphipolis
          (places where Paul stopped when he traveled from Philippi to
          Thessaloniki: Act 17:1). After settling into our lodgings, we will
          have opportunity to explore the charming port city of Kavala, the one
          Paul sailed into from Troas in Turkey/Asia Minor after he received the
          “Macedonian vision” (Acts 16:6-12). (overnight at Kavala)
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(6);
            }}
          >
            See the photo...
          </button>
        </p>
      </div>
      {/*Box 6*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[6] ? "flex" : "none" }}
      >
        <div className={styles.innerBoxPicture}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(6);
            }}
          />
          <img className={styles.justPicture} src={picture_6} alt="Philippi" />
        </div>
      </div>
      {/*Day 7*/}
      <div>
        <h2 className={styles.dayNumber}>Day 7, Monday - April 7</h2>
        <p className="text">
          On this day, with the services of a professional local Greek guide, we
          will tour the archaeological site of ancient Philippi, where Paul and
          Silas were imprisoned for casting a spirit of divination out of a
          young slave girl and where they baptized their first European convert,
          Lydia, by the stream beside the town. Dan will hold an on-site Bible
          study on Acts and Philippians. In addition to this being the site of
          the first European church, this was also the scene of the great Roman
          civil war battle that took place in 42 BC between the forces of
          Octavius (Caesar Augustus) and Mark Anthony against the legions loyal
          to Brutus and Cassius, Roman senators, who were instigators in the
          murder of Julius Caesar two years earlier. This battle changed the
          ancient world forever as Rome was transformed into an empire from a
          republic. (overnight at Kavala)
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(7);
            }}
          >
            See the photo...
          </button>
        </p>
      </div>
      {/*Box 7*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[7] ? "flex" : "none" }}
      >
        <div className={styles.innerBoxPicture}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(7);
            }}
          />
          <img className={styles.justPicture} src={picture_7} alt="Philippi" />
        </div>
      </div>
      {/*Day 8*/}
      <div>
        <h2 className={styles.dayNumber}>Day 8, Tusday - April 8</h2>
        <p className="text">
          This will be a travel day from Philippi to Meteora in the mountains of
          central Greece, with a stop in Berea. Here we will visit the “alter of
          St Paul” and an ancient synagogue in the Jewish Quarter, believed to
          be the very place of a first-century synagogue that Paul spoke in. “As
          soon as it was night, the believers sent Paul and Silas away to Berea.
          On arriving there, they went to the Jewish synagogue. Now the Berean
          Jews were of more noble character than those in Thessalonica, for they
          received the message with great eagerness and examined the Scriptures
          every day to see if what Paul said was true. As a result, many of them
          believed, as did also a number of prominent Greek women and many Greek
          men. After of a late lunch, we will leave Berea and drive to the
          stunningly beautiful area of Meteora and stay in the small town of
          Kalampaka. Speaking for both Martha and me, this is definitely one of
          our favorite places in all of Greece. (overnight at Kalampaka)
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            See the photo...
          </button>
        </p>
      </div>
      {/*Box 8*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[8] ? "flex" : "none" }}
      >
        <div className={styles.innerBoxPicture}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(8);
            }}
          />
          <img className={styles.justPicture} src={picture_8} alt="Meteora" />
        </div>
      </div>
      {/*Day 9*/}
      <div>
        <h2 className={styles.dayNumber}>Day 9, Wednesday - April 9</h2>
        <p className="text">
          Meteora: Our visit here will take in at least two of the stunning
          monasteries built on the cliffs of central Greece. There are six
          Meteora monasteries perched on the huge cliffs people can visit today.
          Serene, mystical, extraordinary, breathtaking, immense, inspiring,
          impressive. These are only some of the words people very often use in
          an effort to describe the Meteora monasteries. (overnight at
          Kalampaka)
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(9);
            }}
          >
            See the photo...
          </button>
        </p>
      </div>
      {/*Box 9*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[9] ? "flex" : "none" }}
      >
        <div className={styles.innerBoxPicture}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(9);
            }}
          />
          <img
            className={styles.justPicture}
            src={picture_9}
            alt="Meteora Monastery"
          />
        </div>
      </div>
      {/*Day 10*/}
      <div>
        <h2 className={styles.dayNumber}>Day 10, Thursday - April 10</h2>
        <p className="text">
          On this day, we will drive to Corinth with a stop and lunch at the
          strait of Actium, the place where Octavian gained his celebrated
          victory over the forces of Mark Antony and Cleopatra in 31 BC. This
          naval battle resulted in Octavian (Augustus Caesar) being the sole
          emperor of the Roman Empire. This same man would later give the degree
          that moved Joseph and Mary to Bethlehem, where Jesus Christ was born,
          fulfilling the prophecy of Micah the prophet. We will also visit the
          camp of Octavian, where he later set up a memorial to himself for
          winning that historic battle. (overnight at Corinth)
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(10);
            }}
          >
            See the photo...
          </button>
        </p>
      </div>
      {/*Box 10*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[10] ? "flex" : "none" }}
      >
        <div className={styles.innerBoxPicture}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(10);
            }}
          />
          <img className={styles.justPicture} src={picture_10} alt="Corinth" />
        </div>
      </div>
      {/*Day 11*/}
      <div>
        <h2 className={styles.dayNumber}>Day 11, Friday - April 11</h2>
        <p className="text">
          After a morning lecture from Dan Glick on Acts and the epistles to the
          Corinthians, we will tour the ancient site of Corinth with a local
          professional Greek tour guide, where Paul founded a church and spent
          at least 18 months on his second missionary journey. We will stand at
          the “bema” (seat of judgment), the place Paul stood before the
          proconsul of Achaia, Gallio, as recorded in Acts 18:12-17. Later in
          the day, we will drive to Cenchrea, a port where Paul sailed away from
          the Isthmus of Corinth. This will be followed by a walk over the
          bridge spanning the Corinth Canal. (overnight at Corinth)
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(11);
            }}
          >
            See the photo...
          </button>
        </p>
      </div>
      {/*Box 11*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[11] ? "flex" : "none" }}
      >
        <div className={styles.innerBoxPicture}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(11);
            }}
          />
          <img className={styles.justPicture} src={picture_11} alt="Corinth" />
        </div>
      </div>
      {/*Day 12*/}
      <div>
        <h2 className={styles.dayNumber}>Day 12, Saturday - April 12</h2>
        <p className="text">Depart Greece</p>
      </div>
    </div>
  );
}

export default GreeceItinerary;
